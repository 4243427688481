"use client"; // If this is necessary; otherwise, consider removing it for server-side rendering

import { Inter } from "next/font/google";
import "./globals.css";
import Core from "../components/base_html/index";
import Script from "next/script";
import { LanguageProvider } from "@/context/LanguageContext";
import { useRouter } from "next/navigation"; // Use only if necessary

const inter = Inter({ subsets: ["latin"] });

// Define OGImage Interface
interface OGImage {
  url: string;
  width?: number;
  height?: number;
  alt?: string;
}

// Define OpenGraph Interface
interface OpenGraph {
  title: string;
  description: string;
  url: string;
  type: string;
  images: OGImage[];
  locale?: string;
  siteName?: string;
  // ... any other properties
}

// Define TwitterMetadata Interface
interface TwitterMetadata {
  card: string;
  title: string;
  description: string;
  images: string[];
}

// Define CustomMetadata Interface (Renamed from Metadata)
interface CustomMetadata {
  title: string;
  description: string;
  keywords: string[];
  openGraph?: OpenGraph;
  twitter?: TwitterMetadata;
  // ... any other properties
}

// Function to get metadata dynamically based on locale
const getMetadata = (locale: string): CustomMetadata => {
  return {
    title:
      locale === "fa"
        ? "BinaExperts | پلتفرم بینایی ماشین"
        : "BinaExperts | Machine Vision Platform",
    description:
      locale === "fa"
        ? "بینااکسپرتز ارائه‌دهنده راهکارهای پیشرفته بینایی ماشین به عنوان پلتفرم بینایی ماشین"
        : "BinaExperts provides advanced machine vision solutions.",
    keywords:
      locale === "fa"
        ? [
            "BinaExperts",
            "بینااکسپرتز",
            "دیتاست رایگان",
            "دیتاست",
            "دیتاست‌های سفارشی",
            "آموزش هوش مصنوعی",
            "بینایی ماشین",
            "هوش مصنوعی",
            "پلتفرم بینایی ماشین",
            "یادگیری ماشین",
            "پردازش تصویر",
            "مدل‌های آماده",
            "مدل‌های یادگیری ماشین",
            "آموزش با یک کلیک",
            "هوش مصنوعی در صنعت",
            "راهکارهای هوش مصنوعی",
            "راهکارهای صنعتی",
            "کنترل کیفیت",
            "زنجیره تامین",
            "کامپیوتر ویژن در صنعت",
            "مکانیزم‌های بینایی ماشین",
            "انجین‌های بینایی ماشین",
            "ارائه مدل‌ها",
            "داده‌گذاری",
            "استقرار مدل",
          ]
        : [
            "BinaExperts",
            "machine vision",
            "AI education",
            "industrial AI",
            "machine learning",
            "artificial intelligence",
            "datasets",
            "quality control",
            "computer vision platform",
          ],
    openGraph: {
      title:
        locale === "fa"
          ? "BinaExperts | پلتفرم بینایی ماشین"
          : "BinaExperts | Machine Vision Platform",
      description:
        locale === "fa"
          ? "بینااکسپرتز ارائه‌دهنده راهکارهای پیشرفته بینایی ماشین برای صنایع مختلف."
          : "BinaExperts provides advanced machine vision solutions for various industries.",
      url: "https://binaexperts.com",
      type: "website",
      images: [
        {
          url: "https://binaexperts.com/og-image.jpg",
          width: 1200,
          height: 630,
          alt: locale === "fa" ? "لوگوی بینااکسپرتز" : "BinaExperts Logo",
        },
      ],
      locale: locale === "fa" ? "fa_IR" : "en_US",
      siteName: "BinaExperts",
      // ... any other openGraph properties
    },
    twitter: {
      card: "summary_large_image",
      title:
        locale === "fa"
          ? "BinaExperts | پلتفرم بینایی ماشین"
          : "BinaExperts | Machine Vision Platform",
      description:
        locale === "fa"
          ? "بینااکسپرتز ارائه‌دهنده راهکارهای پیشرفته بینایی ماشین برای صنایع مختلف."
          : "BinaExperts provides advanced machine vision solutions for various industries.",
      images: ["https://binaexperts.com/twitter-image.jpg"],
    },
    // ... any other metadata properties
  };
};

export default function RootLayout({
  children,
  params,
}: {
  children: React.ReactNode;
  params: { locale?: string };
}) {
  const locale = params.locale || "fa";

  const metadata = getMetadata(locale);

  return (
    <html
      lang={locale}
      dir={locale === "fa" || locale === "ar" ? "rtl" : "ltr"}
    >
      <head>
        {/* Favicon and Icons */}
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />

        {/* Canonical URL */}
        <link rel="canonical" href={`https://binaexperts.com/${locale}`} />

        {/* Meta Author */}
        <meta name="author" content="BinaExperts" />

        {/* Meta Description and Keywords */}
        <meta name="description" content={metadata.description || undefined} />
        <meta
          name="keywords"
          content={metadata.keywords ? metadata.keywords.join(", ") : undefined}
        />

        {/* Open Graph Meta Tags */}
        {metadata.openGraph && (
          <>
            <meta
              property="og:title"
              content={metadata.openGraph.title || undefined}
            />
            <meta
              property="og:description"
              content={metadata.openGraph.description || undefined}
            />
            <meta
              property="og:url"
              content={metadata.openGraph.url || undefined}
            />
            <meta
              property="og:site_name"
              content={metadata.openGraph.siteName || undefined}
            />
            <meta
              property="og:image"
              content={
                metadata.openGraph.images[0]?.url ||
                "https://binaexperts.com/default-og-image.jpg"
              }
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta
              property="og:locale"
              content={metadata.openGraph.locale || undefined}
            />
            <meta
              property="og:type"
              content={metadata.openGraph.type || "website"} // Provide a default if needed
            />
          </>
        )}

        {/* Twitter Meta Tags */}
        {metadata.twitter && (
          <>
            <meta name="twitter:card" content={metadata.twitter.card} />
            <meta name="twitter:title" content={metadata.twitter.title} />
            <meta
              name="twitter:description"
              content={metadata.twitter.description}
            />
            {metadata.twitter.images.map((imageUrl, index) => (
              <meta key={index} name="twitter:image" content={imageUrl} />
            ))}
          </>
        )}

        {/* Structured Data */}
        <Script
          id="structured-data"
          type="application/ld+json"
          strategy="afterInteractive"
        >
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "BinaExperts",
              "url": "https://binaexperts.com",
              "logo": "https://binaexperts.com/logo.png",
              "sameAs": [
                "https://www.facebook.com/binaexperts",
                "https://www.twitter.com/binaexperts",
                "https://www.linkedin.com/company/binaexperts",
                "https://www.instagram.com/binaexperts",
                "https://www.youtube.com/@binaexperts"
              ]
            }
          `}
        </Script>

        {/* Google Analytics Script */}
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=G-GYM3RNPRNN"
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-GYM3RNPRNN');
          `}
        </Script>

        {/* Google Tag Manager Script */}
        <Script id="google-tag-manager" strategy="afterInteractive">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-596F9L4F');
          `}
        </Script>

        {/* Google Ads Conversion Tracking */}
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-16688388548"
          strategy="afterInteractive"
        ></Script>
        <Script id="google-ads" strategy="afterInteractive">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16688388548');
          `}
        </Script>
      </head>
      <body className={inter.className}>
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-596F9L4F"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        {/* End Google Tag Manager (noscript) */}

        {/* Google Ads (noscript) */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=AW-16688388548"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>

        {/* Main Content */}
        <LanguageProvider>
          <Core>{children}</Core>
        </LanguageProvider>
      </body>
    </html>
  );
}
