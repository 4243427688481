"use client";
import Link from "next/link";
import Icon from "@mdi/react";
import { mdiWeb } from "@mdi/js";
import { mdiChevronDown } from "@mdi/js";
import { mdiChevronUp } from "@mdi/js";
import { mdiChevronLeft } from "@mdi/js";
import { mdiChevronRight } from "@mdi/js";
import { useEffect, useRef, useState } from "react";
import { mdiClose } from "@mdi/js";
import { mdiArrowLeftThin } from "@mdi/js";
import { mdiArrowRightThin } from "@mdi/js";
import { IoMenu, IoClose } from "react-icons/io5";
import { BiAdjust } from "react-icons/bi";
import "./header.css";
import Image from "next/image";
import headerLogo from "../../../public/icons/logo.png";
import { useLanguage } from "@/context/LanguageContext";
import { translate } from "@/Hooks/translate";

// Icons
import Product_1 from "@/public/icons/menu-icons/product-1.svg";
import Product_2 from "@/public/icons/menu-icons/product-2.svg";
import Pricing_1 from "@/public/icons/menu-icons/pricing-1.svg";
import Resource_1 from "@/public/icons/menu-icons/resource-1.svg";
import Head from "next/head";

const MobileMenu: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose,
}) => {
  const { language, changeLanguage } = useLanguage();

  const [ltr, setLtr] = useState(false);

  useEffect(() => {
    language === "en" || language === "tr" ? setLtr(true) : setLtr(false);
  }, [language]);

  const [selectLanguage, setSelectLanguage] = useState(false);

  const selectLanguageHandler = () => {
    setSelectLanguage((prev) => !prev);
  };

  const [menuRapper, setMenuRapper] = useState<MenuRapperState>({
    menu_one: false,
    menu_two: false,
    menu_three: false,
    menu_four: false,
  });

  const [closingMenu, setClosingMenu] = useState<keyof MenuRapperState | null>(
    null
  );

  useEffect(() => {
    const isAnyMenuOpen = Object.values(menuRapper).some(
      (menu) => menu === true
    );
    if (isAnyMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // Clean up function to reset the overflow style when the component is unmounted
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuRapper]);

  const menuRapperHandler = (menuName: keyof MenuRapperState) => {
    if (menuRapper[menuName]) {
      // Close the currently open menu
      setMenuRapper({
        menu_one: false,
        menu_two: false,
        menu_three: false,
        menu_four: false,
      });
    } else {
      const currentlyOpenMenu = Object.keys(menuRapper).find(
        (key) => menuRapper[key as keyof MenuRapperState]
      );
      if (currentlyOpenMenu) {
        setClosingMenu(currentlyOpenMenu as keyof MenuRapperState);
        setTimeout(() => {
          setMenuRapper({
            menu_one: false,
            menu_two: false,
            menu_three: false,
            menu_four: false,
            [menuName]: true,
          });
          setClosingMenu(null);
        }, 300); // Duration of the closing animation
      } else {
        setMenuRapper({
          menu_one: false,
          menu_two: false,
          menu_three: false,
          menu_four: false,
          [menuName]: true,
        });
      }
    }
  };

  interface languageList {
    code: string;
    name: string;
  }

  const [languageList, setLanguageList] = useState([
    { name: "فارسی", code: "fa" },
    { name: "English", code: "en" },
    { name: "العربية", code: "ar" },
    { name: "Türkçe", code: "tr" },
  ]);

  const mobileChangeLanguage = (language: string) => {
    changeLanguage(language);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable body scrolling
    } else {
      document.body.style.overflow = ""; // Reset body scrolling
    }

    return () => {
      document.body.style.overflow = ""; // Reset on unmount
    };
  }, [isOpen]);

  const dropdownRef = useRef(null);

  return (
    <header>
      <div className={`mobile-navbar ${isOpen ? "visible" : "hidden"}`}>
        <Head>
          <link rel="preload" href={"@/public/icons/logo.png"} as="image" />
        </Head>
        <ul className="flex flex-col font-medium rounded-lg rtl:space-x-reverse  mb-6">
          <li>
            <div
              className="flex flex-col py-6 text-gray-900 rounded dark:border-gray-700"
              onClick={() => menuRapperHandler("menu_one")}
            >
              <div
                className={`flex justify-between ${
                  menuRapper.menu_one ? "text-blue-500" : null
                }`}
              >
                {translate("navbar.menu.one", language)}
                {menuRapper.menu_one ? (
                  <Icon path={mdiChevronDown} size={1} />
                ) : ltr ? (
                  <Icon path={mdiChevronRight} size={1} />
                ) : (
                  <Icon path={mdiChevronLeft} size={1} />
                )}
              </div>
              <div
                className={`mobile_submenu ${
                  menuRapper.menu_one ? "mobile_show" : ""
                } ${closingMenu === "menu_one" ? "hide" : ""}`}
              >
                <button
                  className="mt-6 py-2 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6] flex items-center gap-3"
                  onClick={() =>
                    (window.location.href = `/${language}/contact-us#form`)
                  }
                >
                  <Product_1
                    width={20}
                    height={20}
                    fill="blue"
                    className={ltr ? "mt-[-2px]" : "mt-1"}
                  />
                  {translate("navbar.menu_items.menu_one.item_one", language)}
                </button>
                <button
                  className="mt-2 py-2 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6] flex items-center gap-3"
                  onClick={() => (window.location.href = `/${language}/hub`)}
                >
                  <Product_2
                    width={24}
                    height={24}
                    fill="blue"
                    className={ltr ? "mt-[-2px]" : "mt-1"}
                  />
                  {translate("navbar.menu_items.menu_one.item_two", language)}
                </button>
              </div>
            </div>
          </li>
          {/* <li>
          <Link
            className="block py-2 md:p-0 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:hover:text-green md:dark:hover:bg-transparent dark:border-gray-700"
            href="#"
            onClick={onClose}
          >
            {translate('navbar.menu.two' , language)}
          </Link>
        </li> */}
          <li>
            <div
              className="flex flex-col py-6 text-gray-900 rounded dark:border-gray-700"
              onClick={() => menuRapperHandler("menu_three")}
            >
              <div
                className={`flex justify-between ${
                  menuRapper.menu_three ? "text-blue-500" : null
                }`}
              >
                {translate("navbar.menu.three", language)}
                {menuRapper.menu_three ? (
                  <Icon path={mdiChevronDown} size={1} />
                ) : ltr ? (
                  <Icon path={mdiChevronRight} size={1} />
                ) : (
                  <Icon path={mdiChevronLeft} size={1} />
                )}
              </div>
              <div
                className={`mobile_submenu ${
                  menuRapper.menu_three ? "mobile_show" : ""
                } ${closingMenu === "menu_three" ? "hide" : ""}`}
              >
                <button
                  className="mt-6 py-2 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6] flex items-center gap-3"
                  onClick={() =>
                    (window.location.href = `/${language}${"/pricing"}`)
                  }
                >
                  <Pricing_1
                    width={24}
                    height={24}
                    fill="blue"
                    className={ltr ? "mt-[-4px]" : ""}
                  />
                  {translate("navbar.menu_items.menu_three.item_one", language)}
                </button>
              </div>
            </div>
          </li>
          <li>
            <div
              className="flex flex-col py-6 text-gray-900 rounded dark:border-gray-700"
              onClick={() => menuRapperHandler("menu_four")}
            >
              <div
                className={`flex justify-between ${
                  menuRapper.menu_four ? "text-blue-500" : null
                }`}
              >
                {translate("navbar.menu.four", language)}
                {menuRapper.menu_four ? (
                  <Icon path={mdiChevronDown} size={1} />
                ) : ltr ? (
                  <Icon path={mdiChevronRight} size={1} />
                ) : (
                  <Icon path={mdiChevronLeft} size={1} />
                )}
              </div>
              <div
                className={`mobile_submenu ${
                  menuRapper.menu_four ? "mobile_show" : ""
                } ${closingMenu === "menu_four" ? "hide" : ""}`}
              >
                <Link
                  className="mt-6 py-2 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6] flex items-center gap-3"
                  href="https://docs.binaexperts.com/"
                  target="_blank"
                >
                  <Resource_1
                    width={24}
                    height={24}
                    fill="blue"
                    className={ltr ? "mt-[-4px]" : ""}
                  />
                  {translate("navbar.menu_items.menu_four.item_one", language)}
                </Link>
              </div>
            </div>
          </li>
        </ul>
        <div className="py-4 mt-4 text-[14px] flex flex-col">
          <div
            onClick={selectLanguageHandler}
            className={`w-full ${selectLanguage ? "border-b-2 pb-2" : ""}`}
          >
            <div
              className={`flex items-center justify-between ${
                selectLanguage ? "text-blue-500" : ""
              }`}
            >
              {translate("navbar.select", language)}
              {selectLanguage ? (
                <Icon path={mdiChevronDown} size={1} />
              ) : ltr ? (
                <Icon path={mdiChevronRight} size={1} />
              ) : (
                <Icon path={mdiChevronLeft} size={1} />
              )}
            </div>
            <ul
              ref={dropdownRef}
              className={`language-dropdown ${
                selectLanguage ? "open" : ""
              } flex flex-col items-start gap-2 mt-4`}
            >
              {languageList
                .filter((item) => item.code !== language)
                .map((item) => (
                  <li
                    key={item.code}
                    className={`hover:bg-[#F3F4F6] width-[100%] mr-0 text-center cursor-pointer py-2 rounded`}
                    onClick={() => mobileChangeLanguage(item.code)}
                  >
                    {item.name}
                  </li>
                ))}
            </ul>
          </div>
          <Link
            style={{ marginBottom: "-10px" }}
            className="mt-6"
            href="https://blog.binaexperts.com/"
            target="_blank"
          >
            {translate("navbar.blog", language)}
          </Link>
          <button
            className={`${ltr ? "text-left" : "text-right"} mt-6`}
            onClick={() => (window.location.href = `/${language}${"/event"}`)}
          >
            {translate("navbar.event", language)}
          </button>
          <button
            className={`${ltr ? "text-left" : "text-right"} mt-6`}
            onClick={() =>
              (window.location.href = `/${language}${"/contact-us"}`)
            }
          >
            {translate("navbar.contact", language)}
          </button>
          <button
            className={`${ltr ? "text-left" : "text-right"} mt-6`}
            onClick={() => (window.location.href = `/${language}${"/faq"}`)}
          >
            {translate("navbar.faq", language)}
          </button>
        </div>
        <div className="flex justify-between items-center gap-4 mt-12 buttons ">
          <button
            onClick={() => (window.location.href = `/${language}/contact-us`)}
            type="button"
            className="w-[45%] text-white bg-pink focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center "
          >
            {translate("navbar.btns.Preview", language)}
          </button>
          <button
            onClick={() => window.open("https://app.binaexperts.com", "_blank")}
            type="button"
            className="w-[45%] text-white bg-green focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center mr-0"
          >
            {translate("navbar.btns.login", language)}
          </button>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </header>
  );
};

interface MenuRapperState {
  menu_one: boolean;
  menu_two: boolean;
  menu_three: boolean;
  menu_four: boolean;
}

interface LanguageDropdownProps {
  selectLanguage: boolean;
  languageList: Array<{ code: string; name: string }>;
  language: string;
  changeLanguage: (code: string) => void;
  ltr: boolean;
}

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { language, changeLanguage } = useLanguage();

  const [languageList, setLanguageList] = useState([
    { name: "فارسی", code: "fa" },
    { name: "English", code: "en" },
    { name: "العربية", code: "ar" },
    { name: "Türkçe", code: "tr" },
  ]);

  const [selectLanguage, setSelectLanguage] = useState(false);

  const [ltr, setLtr] = useState(false);

  useEffect(() => {
    language === "en" || language === "tr" ? setLtr(true) : setLtr(false);
  }, [language]);

  const scrollHeader = () => {
    setScrolled(window.scrollY >= 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHeader);

    return () => {
      window.removeEventListener("scroll", scrollHeader);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen); // Toggle mobile menu state
  };

  const iconChangeLanguage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setSelectLanguage((prev) => !prev);
  };

  useEffect(() => {
    setSelectLanguage(false);
  }, [language]);

  const [menuRapper, setMenuRapper] = useState<MenuRapperState>({
    menu_one: false,
    menu_two: false,
    menu_three: false,
    menu_four: false,
  });

  const [closingMenu, setClosingMenu] = useState<keyof MenuRapperState | null>(
    null
  );

  useEffect(() => {
    const isAnyMenuOpen = Object.values(menuRapper).some(
      (menu) => menu === true
    );
    if (isAnyMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // Clean up function to reset the overflow style when the component is unmounted
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuRapper]);

  const subMenuHandler = (menuName: keyof MenuRapperState) => {
    if (menuRapper[menuName]) {
      // Close the currently open menu
      setMenuRapper({
        menu_one: false,
        menu_two: false,
        menu_three: false,
        menu_four: false,
      });
    } else {
      const currentlyOpenMenu = Object.keys(menuRapper).find(
        (key) => menuRapper[key as keyof MenuRapperState]
      );
      if (currentlyOpenMenu) {
        setClosingMenu(currentlyOpenMenu as keyof MenuRapperState);
        setTimeout(() => {
          setMenuRapper({
            menu_one: false,
            menu_two: false,
            menu_three: false,
            menu_four: false,
            [menuName]: true,
          });
          setClosingMenu(null);
        }, 300); // Duration of the closing animation
      } else {
        setMenuRapper({
          menu_one: false,
          menu_two: false,
          menu_three: false,
          menu_four: false,
          [menuName]: true,
        });
      }
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    // Function to handle click events
    const handleClick = () => {
      // Do something each time the user clicks
      selectLanguage ? setSelectLanguage(false) : null;
    };

    // Add event listener to the document
    document.addEventListener("click", handleClick);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [selectLanguage]);

  const closeAllMenus = () => {
    setMenuRapper({
      menu_one: false,
      menu_two: false,
      menu_three: false,
      menu_four: false,
    });
  };

  const [notifStatus, setNotifStatus] = useState(true);

  //this is for select language section transition
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (selectLanguage) {
      setTimeout(() => setIsVisible(true), 10); // Start animation after DOM is updated
    } else {
      setIsVisible(false);
    }
  }, [selectLanguage]);

  const directionClass = ltr ? "ltr" : "rtl";
  const arrowPath = ltr ? mdiArrowRightThin : mdiArrowLeftThin;
  const arrowTransform = ltr ? "translate-x-1" : "-translate-x-1";

  return (
    <header>
      <nav
        className={`w-full fixed top-0 z-[99] navbar ${
          scrolled ? "navbar-off" : "navbar-on"
        }`}
      >
        <div
          className={`max-w-screen-xl flex flex-wrap items-center mx-auto pt-1 pb-1 px-8 justify-end display-mobile-off navbar-top-menu`}
        >
          <div className="    hidden  md:flex py-1">
            <div className="">
              <ul
                style={{ display: "flex", alignItems: "center" }}
                className="flex flex-col font-medium text-sm p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-4 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 text-[#000]"
              >
                <li>
                  <Link
                    className="hover:text-green text-10xl"
                    href="https://blog.binaexperts.com/"
                    target="_blank"
                    onClick={closeAllMenus}
                  >
                    {translate("navbar.blog", language)}
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-green"
                    href={`/${language}${"/faq"}`}
                    prefetch={true}
                    onClick={closeAllMenus}
                  >
                    {translate("navbar.faq", language)}
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-green"
                    href={`/${language}${"/event"}`}
                    prefetch={true}
                    onClick={closeAllMenus}
                  >
                    {translate("navbar.event", language)}
                  </Link>
                </li>
                <li>
                  <Link
                    className="hover:text-green"
                    href={`/${language}/contact-us`}
                    prefetch={true}
                    onClick={closeAllMenus}
                  >
                    {translate("navbar.contact", language)}
                  </Link>
                </li>
                <li>
                  <span className="text-[1rem]">|</span>
                </li>
                <li className="relative mt-[-4px]">
                  <Link
                    className="hover flex"
                    href="/"
                    onClick={(e) => iconChangeLanguage(e)}
                  >
                    {!selectLanguage ? (
                      <Icon
                        className="mt-[2px]"
                        path={mdiChevronDown}
                        size={0.6}
                      />
                    ) : (
                      <Icon
                        className="mt-[2px]"
                        path={mdiChevronUp}
                        size={0.6}
                      />
                    )}
                    <Icon path={mdiWeb} size={0.8} />
                  </Link>

                  {/* {selectLanguage && 
                  <div className={`absolute bg-[#fff] top-8 p-2 rounded border border-solid ${ltr ? "left-[-55px]" : "left-0"} z-50 `}>
                    <ul className="flex flex-col gap-2">
                      {languageList.filter(item => item.code !== language).map(item => (
                        <li 
                          key={item.code} // Add the key prop here
                          className={`hover:bg-[#F3F4F6] w-full mr-0 text-center cursor-pointer py-2 px-4 rounded`}
                          onClick={() => changeLanguage(item.code)}
                        >
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                } */}
                  <div
                    className={`absolute bg-white top-8 p-2 rounded border border-solid transition-all duration-300 ease-out transform 
                    ${ltr ? "left-[-55px]" : "left-0"} z-50 
                    ${
                      isVisible
                        ? "opacity-100 max-h-screen translate-y-0"
                        : "opacity-0 max-h-0 -translate-y-5"
                    } `}
                    style={{ overflow: "hidden" }}
                  >
                    <ul className="flex flex-col gap-2">
                      {languageList
                        .filter((item) => item.code !== language)
                        .map((item) => (
                          <li
                            key={item.code}
                            className="hover:bg-gray-200 w-full mr-0 text-center cursor-pointer py-2 px-4 rounded"
                            onClick={() => {
                              setIsVisible(false);
                              setTimeout(() => {
                                changeLanguage(item.code);
                              }, 200);
                            }}
                          >
                            {item.name}
                          </li>
                        ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="h-[1px] w-full bg-[#ebebeb] mt-1"></div> */}
        </div>
        <div
          className={`max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-2 px-8 ResHeader navbar-main-menu`}
        >
          <div className="flex items-center justify-between gap-14">
            <a
              href={`/${language}`}
              style={{ marginTop: "-.5rem" }}
              className="flex items-center space-x-3 order-first rtl:space-x-reverse "
            >
              <Image src={headerLogo} className="h-9 w-[70px]" alt="header-logo" priority/>
            </a>
            <div
              className={`items-center justify-between navbar-menu display-mobile-off ${
                ltr ? "navbar-menu-ltr" : ""
              }`}
              id="navbar-cta"
            >
              <ul className="flex justify-right w-[100%] flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 ">
                <li>
                  <Link
                    className="flex items-center py-2 px-3 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6]"
                    href={`#`}
                    onClick={() => subMenuHandler("menu_one")}
                  >
                    {translate("navbar.menu.one", language)}
                    <Icon
                      className="mx-1"
                      path={menuRapper.menu_one ? mdiChevronUp : mdiChevronDown}
                      size={0.6}
                    />
                  </Link>
                </li>
                {/* <li>
                <Link
                  className="block py-2 px-3 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6]"
                  href={`/${language}/contact-us#form`}
                  onClick={() => subMenuHandler('menu_two')}
                >
                  {translate('navbar.menu.two' , language)}
                </Link>
              </li> */}
                <li>
                  <Link
                    href={`#`}
                    className="flex items-center py-2 px-3 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6]"
                    onClick={() => subMenuHandler("menu_three")}
                  >
                    {translate("navbar.menu.three", language)}
                    <Icon
                      className="mx-1"
                      path={
                        menuRapper.menu_three ? mdiChevronUp : mdiChevronDown
                      }
                      size={0.6}
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    className="flex items-center py-2 px-3 md:p-2 text-gray-900 rounded hover:bg-[#F3F4F6]"
                    href="#"
                    onClick={() => subMenuHandler("menu_four")}
                  >
                    {translate("navbar.menu.four", language)}
                    <Icon
                      className="mx-1"
                      path={
                        menuRapper.menu_four ? mdiChevronUp : mdiChevronDown
                      }
                      size={0.6}
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className=" order-2 space-x-3 md:space-x-0 rtl:space-x-reverse md:flex flex items-center gap-4 navbar-buttons display-mobile-off">
            <button
              //this code is for scrolling to form when click on prev button in navbar
              onClick={() =>
                (window.location.href = `/${language}/contact-us#form`)
              }
              type="button"
              style={{ margin: "0", height: "35px" }}
              className="text-white bg-pink hover:bg-[#ef1a81] focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center dark:hover:bg-[#ef1a81] ml-2"
            >
              {translate("navbar.btns.Preview", language)}
            </button>
            <button
              onClick={() =>
                window.open("https://app.binaexperts.com", "_blank")
              }
              type="button"
              style={{ margin: "0", height: "35px" }}
              className="text-green border border-green  hover:bg-green hover:text-white focus:outline-none font-medium rounded-lg text-sm px-4 py-2 text-center"
            >
              {translate("navbar.btns.login", language)}
            </button>
          </div>

          <div className="py-8 IconResHeader">
            <button
              className="py-3 rounded-md"
              onClick={handleMobileMenuToggle}
            >
              {mobileMenuOpen ? <IoClose size={25} /> : <IoMenu size={25} />}
            </button>
          </div>
        </div>
        {notifStatus && (
          <div className="max-w-screen-xl px-8 mx-auto hidden md:block">
            <div className="h-[1px] w-full bg-[#ebebeb] mt-1"></div>
          </div>
        )}

        {notifStatus && (
          <div className="bg-[#fff] w-full overflow-hidden notif-bar-container">
            <div className="max-w-screen-xl mx-auto h-full px-8 flex items-center justify-between text-black py-2 relative overflow-hidden">
              <div className="marquee-container relative overflow-hidden flex items-center justify-start">
                <div
                  className={`${
                    ltr ? "marquee-content-ltr" : "marquee-content"
                  } whitespace-nowrap flex space-x-8`}
                >
                  <Link
                    href={`/${language}/hub`}
                    target="_blank"
                    className="inline-block flex-none text-center"
                  >
                    {translate("navbar.notif", language)}
                  </Link>
                </div>
              </div>
              <div
                className={`cursor-pointer absolute ${
                  ltr ? "right-0 pr-8" : "left-0 pl-8"
                } top-0 bottom-0 flex items-center`}
                onClick={() => setNotifStatus(false)}
              >
                <span className="text-md text-[#ccc] hover:text-[#000] font-bold transition-opacity duration-500 ease-out opacity-100 hover:opacity-75">
                  <Icon path={mdiClose} size={0.8} />
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="bg-[#F5F7FA]">
          <div className="max-w-screen-xl mx-auto px-8">
            <div
              className={`submenu ${menuRapper.menu_one ? "show" : ""} ${
                closingMenu === "menu_one" ? "hide" : ""
              }`}
            >
              {/* First Button */}
              <div className="mt-20 flex gap-2">
                <button
                  className={`py-2 text-gray-900 flex items-center gap-2 menu-hover-btn transition-colors duration-300 ease-in-out ${
                    ltr ? "ltr" : "rtl"
                  }`}
                  onClick={() =>
                    (window.location.href = `/${language}/contact-us#form`)
                  }
                >
                  <Product_1
                    width={24}
                    height={24}
                    fill="blue"
                    className={ltr ? "mb-0" : "mt-1"}
                  />

                  {/* Translated Text */}
                  {translate("navbar.menu_items.menu_one.item_one", language)}

                  {/* Arrow Icon */}
                  <Icon
                    path={ltr ? mdiArrowRightThin : mdiArrowLeftThin}
                    size={1}
                    className="hover-icon"
                  />
                </button>
              </div>

              {/* Second Button */}
              <div className="mt-4">
                <button
                  className={`py-2 text-gray-900 flex items-center gap-2 menu-hover-btn transition-colors duration-300 ease-in-out ${
                    ltr ? "ltr" : "rtl"
                  }`}
                  onClick={() => (window.location.href = `/${language}/hub`)}
                >
                  <Product_2
                    width={24}
                    height={24}
                    fill="blue"
                    className={ltr ? "mb-0" : "mt-1"}
                  />

                  {/* Translated Text */}
                  {translate("navbar.menu_items.menu_one.item_two", language)}

                  {/* Arrow Icon */}
                  <Icon
                    path={ltr ? mdiArrowRightThin : mdiArrowLeftThin}
                    size={1}
                    className="hover-icon"
                  />
                </button>
              </div>
            </div>
            <div
              className={`submenu ${menuRapper.menu_two ? "show" : ""} ${
                closingMenu === "menu_two" ? "hide" : ""
              }`}
            >
              <div className="mt-20">Submenu two</div>
            </div>
            <div
              className={`submenu ${menuRapper.menu_three ? "show" : ""} ${
                closingMenu === "menu_three" ? "hide" : ""
              }`}
            >
              <div className="mt-20">
                <button
                  className={`py-2 text-gray-900 flex items-center gap-2 menu-hover-btn transition-colors duration-300 ease-in-out ${
                    ltr ? "ltr" : "rtl"
                  }`}
                  onClick={() =>
                    (window.location.href = `/${language}${"/pricing"}`)
                  }
                >
                  <Pricing_1
                    width={24}
                    height={24}
                    fill="blue"
                    className={ltr ? "mb-1" : ""}
                  />
                  {translate("navbar.menu_items.menu_three.item_one", language)}
                  {ltr ? (
                    <Icon
                      path={mdiArrowRightThin}
                      size={1}
                      className="hover-icon"
                    />
                  ) : (
                    <Icon
                      path={mdiArrowLeftThin}
                      size={1}
                      className="hover-icon"
                    />
                  )}
                </button>
              </div>
            </div>
            <div
              className={`submenu ${menuRapper.menu_four ? "show" : ""} ${
                closingMenu === "menu_four" ? "hide" : ""
              }`}
            >
              <div className="mt-20">
                <button
                  className={`py-2 text-gray-900 flex items-center gap-2 menu-hover-btn transition-colors duration-300 ease-in-out ${
                    ltr ? "ltr" : "rtl"
                  }`}
                  onClick={() =>
                    window.open("https://docs.binaexperts.com/", "_blank")
                  }
                >
                  <Resource_1
                    width={24}
                    height={24}
                    fill="blue"
                    className={ltr ? "mb-1" : ""}
                  />
                  {translate("navbar.menu_items.menu_four.item_one", language)}
                  {ltr ? (
                    <Icon
                      path={mdiArrowRightThin}
                      size={1}
                      className="hover-icon"
                    />
                  ) : (
                    <Icon
                      path={mdiArrowLeftThin}
                      size={1}
                      className="hover-icon"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <MobileMenu isOpen={mobileMenuOpen} onClose={handleMobileMenuToggle} />
      </nav>
    </header>
  );
};

export default Header;
