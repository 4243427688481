// const backgroundImagePath = "../icons/footer.png";
import backgroundImagePath from "../../../public/icons/footer.png";
import FooterLogo from "../../../public/icons/logo.png";
import namad1 from "../../../public/icons/nmad1.png";
import namad2 from "../../../public/icons/nmad2.png";
import Link from "next/link";
import "./footer.css";
import Image from "next/image";

import { useLanguage } from "@/context/LanguageContext";
import { translate } from "@/Hooks/translate";
import { useEffect, useRef, useState } from "react";

//social media icons
import Icon from "@mdi/react";
import { mdiInstagram } from "@mdi/js";
import { mdiLinkedin } from "@mdi/js";
import { mdiWeb } from "@mdi/js";
import { mdiChevronDown } from "@mdi/js";
import { mdiChevronUp } from "@mdi/js";

export default function Footer() {
  const { language, changeLanguage } = useLanguage();

  const [languageList, setLanguageList] = useState([
    { name: "فارسی", code: "fa" },
    { name: "English", code: "en" },
    { name: "العربية", code: "ar" },
    { name: "Türkçe", code: "tr" },
  ]);

  const [selectLanguage, setSelectLanguage] = useState(false);

  const [ltr, setLtr] = useState(false);

  useEffect(() => {
    language === "en" || language === "tr" ? setLtr(true) : setLtr(false);
  }, [language]);

  useEffect(() => {
    setSelectLanguage(false);
  }, [language]);

  const iconChangeLanguage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setSelectLanguage((prev) => !prev);
  };

  const imageStyle = {
    transform: `scaleX(${ltr ? "-1" : "1"})`,
  };

  const openPopup = () => {
    window.open(
      "https://logo.samandehi.ir/Verify.aspx?id=372160&p=xlaojyoeuiwkrfthgvkaobpd",
      "Popup",
      "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30"
    );
  };

  //this is for select language section transition
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Create a ref to track the dropdown
  const buttonRef = useRef<HTMLDivElement>(null); // Ref for the button

  useEffect(() => {
    if (selectLanguage) {
      setTimeout(() => setIsVisible(true), 10); // Small delay to trigger animation after DOM renders
    } else {
      setIsVisible(false);
    }
  }, [selectLanguage]);

  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the click is outside the dropdown and not on the button
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false); // Close the dropdown
        setSelectLanguage(false); // Set selectLanguage to false to sync state
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, buttonRef]);

  return (
    <footer className="w-full">
      <div
        className="bg-center bg-no-repeat bg-cover py-4 footer-parent"
        style={{
          // backgroundImage: `url(${backgroundImagePath})`,

          position: "relative",
        }}
      >
        <Image
          src={backgroundImagePath}
          alt="footer-background-image"
          className="absolute bottom-0 z-10 h-[100%] w-[100%] footer-bg"
          style={imageStyle}
          priority
        />
        <div className="relative mx-auto w-full max-w-screen-xl z-20 px-8">
          <div className="grid grid-cols-1 gap-4 py-6 lg:py-8 md:grid-cols-2 lg:grid-cols-4 footer-main-menu">
            <div className="flex flex-col">
              <h2 className="mb-6 text-md font-semibold text-gray-900 uppercase">
                {translate("footer.menu.row_one.title", language)}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                <li className="mb-4">
                  <a
                    href="https://app.binaexperts.com/"
                    target="_blank"
                    className="hover:text-green text-black"
                  >
                    {translate("footer.menu.row_one.item_one", language)}
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href={`/${language}/hub`}
                    target="_blank"
                    className="hover:text-green text-black"
                  >
                    {translate("footer.menu.row_one.item_two", language)}
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col">
              <h2 className="mb-6 text-md font-semibold text-gray-900 uppercase ">
                {translate("footer.menu.row_two.title", language)}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                <li className="mb-4">
                  <a
                    href="https://docs.binaexperts.com/"
                    target="_blank"
                    className="hover:text-green text-black"
                  >
                    {translate("footer.menu.row_two.item_one", language)}
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col">
              <h2 className="mb-6 text-md font-semibold text-gray-900 uppercase ">
                {translate("footer.menu.row_three.title", language)}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                <li className="mb-4">
                  <a
                    href="https://blog.binaexperts.com/tag/binaexperts-platform/"
                    className="hover:text-green text-black"
                    target="_blank"
                  >
                    {translate("footer.menu.row_three.item_one", language)}
                  </a>
                </li>

                <li className="mb-4">
                  <a
                    href={`/${language}/terms`}
                    className="hover:text-green text-black"
                  >
                    {translate("footer.menu.row_three.item_two", language)}
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href={`/${language}/privacy`}
                    className="hover:text-green text-black"
                  >
                    {translate("footer.menu.row_three.item_three", language)}
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href={`/${language}/faq`}
                    className="hover:text-green text-black"
                  >
                    {translate('navbar.faq' , language)}
                  </a>
                </li>
              </ul>
            </div>

            <div className="flex flex-col">
              <h2 className="mb-6 text-md font-semibold text-gray-900 uppercase ">
                {translate("footer.menu.row_four.title", language)}
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium text-sm">
                <li className="mb-4">
                  <a
                    href={`/${language}/contact-us`}
                    className="hover:text-green text-black"
                  >
                    {translate("footer.menu.row_four.item_one", language)}
                  </a>
                </li>
                <li className="mb-4">
                  <a
                    href="https://blog.binaexperts.com/"
                    target="_blank"
                    className="hover:text-green text-black"
                  >
                    {translate("footer.menu.row_four.item_two", language)}
                  </a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-green text-black"></a>
                </li>
                <li className="mb-4">
                  <a href="#" className="hover:text-green text-black"></a>
                </li>
              </ul>
            </div>
          </div>

          <div className="w-full flex flex-col items-center justify-center sm:flex-row sm:justify-between footer-main-info">
            <div className="w-[50%] text-center sm:text-left md:text-right">
              <div
                style={
                  ltr
                    ? {
                        flexDirection: "row-reverse",
                        justifyContent: "flex-end",
                      }
                    : {}
                }
                className="flex items-center ResFooter mb-4"
              >
                <h3
                  style={{ marginBottom: "-32px" }}
                  className="text-md font-bold flex items-center text-[#000]"
                >
                  {translate("footer.intro.title", language)}
                </h3>
                <a href={`/${language}`}>
                  <Image
                    className="w-[80px] mr-4 ImgResFooter"
                    src={FooterLogo}
                    alt="footer-logo"
                    priority
                  />
                </a>
              </div>
              <p
                style={ltr ? { textAlign: "left" } : {}}
                className=" text-md mt-2 text-right footer-info-text leading-7 text-[#000]"
              >
                {translate("footer.intro.text", language)}
              </p>
            </div>

            <div className="flex mt-4 sm:mt-0 namad-parent">
              <a
                referrerPolicy="origin"
                target="_blank"
                href="https://trustseal.enamad.ir/?id=523459&Code=VVbzu6IS1C1GhrrSGWXhL6DZmZSK8SQA"
              >
                <img
                  referrerPolicy="origin"
                  src="https://trustseal.enamad.ir/logo.aspx?id=523459&Code=VVbzu6IS1C1GhrrSGWXhL6DZmZSK8SQA"
                  alt="E-Namad"
                  style={{ cursor: "pointer" }}
                  data-code="VVbzu6IS1C1GhrrSGWXhL6DZmZSK8SQA"
                />
              </a>
              <img
                referrerPolicy="origin"
                id="rgvjjzpenbqejxlzfukzesgt"
                style={{ cursor: "pointer" }}
                onClick={openPopup}
                alt="logo-samandehi"
                src="https://logo.samandehi.ir/logo.aspx?id=372160&p=qftiyndtodrfnbpdwlbqlyma"
              />
            </div>
          </div>

          <div
            className={`absolute bottom-[3rem] footer-select-language ${
              ltr || language === "ar" ? "ltr-select-language" : ""
            }`}
          >
            {/* {selectLanguage && (
              <div
                className={`absolute w-full bg-[#fff] bottom-16 p-2 rounded border-2 border-solid border-[#ebebeb]`}
              >
                <ul className="flex flex-col gap-2">
                  {languageList
                    .filter((item) => item.code !== language)
                    .map((item) => (
                      <li
                        key={item.code} // Add the key prop here
                        className={`hover:bg-[#F3F4F6] width-[100%] mr-0 text-center cursor-pointer py-2 px-4 rounded select-none`}
                        onClick={() => changeLanguage(item.code)}
                      >
                        {item.name}
                      </li>
                    ))}
                </ul>
              </div>
            )} */}

            <div
              ref={dropdownRef} // Attach the ref to the dropdown
              className={`absolute w-full bg-white bottom-16 p-2 rounded border-2 border-solid border-gray-200 transition-all duration-300 ease-out transform 
              ${
                isVisible
                  ? "opacity-100 max-h-screen translate-y-0"
                  : "opacity-0 max-h-0 translate-y-5"
              } `}
              style={{ overflow: "hidden", boxSizing: "border-box" }}
            >
              <ul className="flex flex-col gap-2">
                {languageList
                  .filter((item) => item.code !== language)
                  .map((item) => (
                    <li
                      key={item.code}
                      className="hover:bg-gray-200 w-full mr-0 text-center cursor-pointer py-2 px-4 rounded select-none"
                      onClick={() => {
                        setIsVisible(false);
                        setTimeout(() => {
                          changeLanguage(item.code);
                        }, 200);
                      }}
                    >
                      {item.name}
                    </li>
                  ))}
              </ul>
            </div>

            <div
              ref={buttonRef}
              className="flex items-center gap-2 bg-[#fff] border px-2 py-2 rounded cursor-pointer"
              onClick={(e) => iconChangeLanguage(e)}
            >
              <Icon path={mdiWeb} size={1} />
              <p className={`${ltr ? "mr-4" : "ml-4"} select-none`}>
                {
                  // Find and display the current language name directly in JSX
                  languageList.find((lang) => lang.code === language)?.name ||
                    "Unknown"
                }
              </p>
              {!selectLanguage ? (
                <Icon className="mt-[2px]" path={mdiChevronUp} size={0.6} />
              ) : (
                <Icon className="mt-[2px]" path={mdiChevronDown} size={0.6} />
              )}
            </div>
          </div>

          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="flex w-[100%] justify-center mb-4 social-icons">
            <div className="flex gap-4">
              <a href="https://www.instagram.com/binaexperts" target="_blank">
                <Icon
                  className="cursor-pointer text-[#833AB4]"
                  path={mdiInstagram}
                  size={1}
                />
              </a>
              <a href="https://t.me/BinaExperts" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="25"
                  height="25"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#29b6f6"
                    d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M33.95,15l-3.746,19.126c0,0-0.161,0.874-1.245,0.874c-0.576,0-0.873-0.274-0.873-0.274l-8.114-6.733 l-3.97-2.001l-5.095-1.355c0,0-0.907-0.262-0.907-1.012c0-0.625,0.933-0.923,0.933-0.923l21.316-8.468 c-0.001-0.001,0.651-0.235,1.126-0.234C33.667,14,34,14.125,34,14.5C34,14.75,33.95,15,33.95,15z"
                  ></path>
                  <path
                    fill="#b0bec5"
                    d="M23,30.505l-3.426,3.374c0,0-0.149,0.115-0.348,0.12c-0.069,0.002-0.143-0.009-0.219-0.043 l0.964-5.965L23,30.505z"
                  ></path>
                  <path
                    fill="#cfd8dc"
                    d="M29.897,18.196c-0.169-0.22-0.481-0.26-0.701-0.093L16,26c0,0,2.106,5.892,2.427,6.912 c0.322,1.021,0.58,1.045,0.58,1.045l0.964-5.965l9.832-9.096C30.023,18.729,30.064,18.416,29.897,18.196z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://linkedin.com/company/BinaExperts"
                target="_blank"
              >
                <Icon
                  className="cursor-pointer text-[#0A66C2]"
                  path={mdiLinkedin}
                  size={1}
                />
              </a>
              <a
                href="https://www.youtube.com/@BinaExperts"
                target="_blank"
                className="md:mt-[-3px]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="28"
                  height="28"
                  viewBox="0 0 48 48"
                >
                  <path fill="red" d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                </svg>
              </a>
            </div>
          </div>
          {language === "fa" ? (
            <span className="block text-sm text-gray-500 text-center pb-6 select-none">
              تمام حقوق مادی و معنوی این وبسایت متعلق به{" "}
              <a href="/" style={{ color: "#11B78F" }}>
                BinaExperts
              </a>{" "}
              میباشد{" "}
            </span>
          ) : language === "en" ? (
            <span className="block text-sm text-gray-500 text-center pb-6 select-none">
              All rights reserved for{" "}
              <a href="/" style={{ color: "#11B78F" }}>
                BinaExperts
              </a>
            </span>
          ) : language === "ar" ? (
            <span className="block text-sm text-gray-500 text-center pb-6 select-none">
              جميع الحقوق محفوظة ل{" "}
              <a href="/" style={{ color: "#11B78F" }}>
                بينا إكسبرتس
              </a>
            </span>
          ) : language === "tr" ? (
            <span className="block text-sm text-gray-500 text-center pb-6 select-none">
              Tüm hakları{" "}
              <a href="/" style={{ color: "#11B78F" }}>
                BinaExperts
              </a>{" "}
              aittir.
            </span>
          ) : null}
        </div>
      </div>
    </footer>
  );
}
