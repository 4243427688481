"use client"
import React, { ReactNode, useState, useEffect } from 'react';
import Footer from "./footer/Footer";
import Header from "./header/header";
import Image from "next/image";
import headerLogo from "@/public/icons/logo.png";
import styles from "./index.module.css";

import Head from 'next/head';

import { useLanguage } from "@/context/LanguageContext";

interface CoreProps {
  children: ReactNode;
}

const Core = ({ children }: CoreProps) => {
  const { language, isLanguageInitialized } = useLanguage();

  const [ltr, setLtr] = useState(false);
  const [loadingText, setLoadingText] = useState(''); // Initialize with an empty string
  const [loading, setLoading] = useState(true);

  const getLoadingText = (lang: string) => {
    switch (lang) {
      case "en":
        return "Loading, please wait.";
      case "tr":
        return "Yükleniyor, lütfen bekleyin.";
      case "ar":
        return "جارٍ التحميل، يرجى الانتظار.";
      default:
        return "در حال بارگذاری ، لطفا صبر کنید."; // Farsi
    }
  };

  useEffect(() => {
    if (isLanguageInitialized) {
      setLoadingText(getLoadingText(language));
      language === "en" || language === "tr" ? setLtr(true) : setLtr(false);
    }
  }, [language, isLanguageInitialized]);

  useEffect(() => {
    const handlePageLoad = () => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    if (document.readyState === 'complete') {
      handlePageLoad();
    } else {
      window.addEventListener('load', handlePageLoad);
      return () => window.removeEventListener('load', handlePageLoad);
    }
  }, []);

  if (!isLanguageInitialized) {
    return null; // or a placeholder spinner if you want
  }

  return (
    <div>
      {!loading ? (
        <>
          <Head>
            <link rel="preload" href={"@/public/icons/logo.png"} as="image" />
          </Head>
          <div>
            <Header />
          </div>
          <div>{children}</div>
          <div>
            <Footer />
          </div>
        </>
      ) : (
        <div className='w-full h-screen flex flex-col justify-center items-center gap-8'>
          <div className='flex justify-center items-center relative'>
            <div className={styles.custom_loader}></div>
            <Image src={headerLogo} className="h-14 w-[130px] absolute" alt="bina-logo" priority />
          </div>
          {loadingText && (
            <h2 className={ltr ? styles.loading_text_ltr : styles.loading_text}>{loadingText}</h2>
          )}
        </div>
      )}
    </div>
  );
};

export default Core;
